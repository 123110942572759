import React from "react"

export default function CategoryHeader(props) {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-semibold text-brand-dark tracking-wide uppercase">{props.headerDetails.preHeader}</h2>
            <p className="mt-1 text-4xl text-gray-500 sm:text-5xl sm:tracking-tight lg:text-5xl">
              {props.headerDetails.mainHeader}
            </p>
            <p className="max-w-6xl mt-5 mx-auto text-xl text-gray-500">
              {props.headerDetails.blurb}
            </p>
          </div>
        </div>
      </div>
    )
  }