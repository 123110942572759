import * as React from 'react'
import Layout from "../../components/layout"
import CategoryHeader from "../../components/categoryheader"
import ProductOverview from '../../components/productoverview'
import ProdutImageNeusal from "../../images/neusal.webp"
import ProdutImageRinseSB from "../../images/rinse.webp"

//TODO Saline Solutions - dividing space between products
//TODO Saline Solutions - Links to Extend at bottom

const headerDetails = 
  {
    mainHeader: 'NEUSAL & RINSE SB',
    preHeader: 'Saline Solutions',
    blurb: `Consol offer two saline solutions for rinsing contact lenses of any type of material.
    The preserved rinsing solution RINSE SB can be used up to three months after the bottle
    has been opened while the preservative free multifunctional solution NEUSAL must be discarded after one month.`
  }

const neusalDetails =
{
  productName: 'NEUSAL',
  productDescription: 'Preservative free multifunctional saline solution',
  productImage: ProdutImageNeusal,
  intendedUsages:
  [
    {name: '1', usage: 'To rinse off preservative and disinfectant before placing lenses on the eye'},
    {name: '2', usage: 'To neutralize and rinse residual hydrogen peroxide off the lenses if used in previous disinfecting step'},
    {name: '3', usage: 'To clean the lenses mechanically through rubbing'},
    {name: '4', usage: 'To moisten and rinse the lenses before placing them on the eye'},
  ],
  contentDescription: 'Extend is a sterile isotonic solution containing the following',
  contents:
  [
    {name: '', content: 'Borate Buffer'},
    {name: '', content: 'Sodium chloride'},
    {name: '', content: 'Active ingredients: sodium thiosulphate 0.5%, poloxamine 0.05%'},
  ],
  contentDisclaimer: 'Does not contain Thiomersal.',
  sizes:
  [
    {name: '', sizeDescription: '100 ml bottles, perfect for the office and travelling'},
  ],
  deviceDisclaimer: 'The bottle must be discarded after it has been opened for 1 month.',
}

const rinseSbDetails =
{
  productName: 'RINSE SB',
  productDescription: 'Preserved rinsing saline solution',
  productImage: ProdutImageRinseSB,
  intendedUsages:
  [
    {name: '1', usage: 'To rinse off preservative and disinfectant before placing lenses on the eye'},
    {name: '2', usage: 'To neutralize and rinse residual hydrogen peroxide off the lenses if used in previous disinfecting step'},
    {name: '3', usage: 'To clean the lenses mechanically through rubbing'},
    {name: '4', usage: 'To moisten and rinse the lenses before placing them on the eye'},
  ],
  contentDescription: 'Extend is a sterile isotonic solution containing the following',
  contents:
  [
    {name: '', content: 'Borate Buffer'},
    {name: '', content: 'Sodium chloride'},
    {name: '', content: 'Active ingredients: sodium thiosulphate 0.5%, poloxamine 0.05%'},
  ],
  contentDisclaimer: 'Does not contain Thiomersal.',
  sizes:
  [
    {name: '', sizeDescription: '100 ml bottles, perfect for the office and travelling'},
  ],
  deviceDisclaimer: 'The bottle must be discarded after it has been opened for 1 month.',
}
export default function Page() {
  return (
    <Layout>
        <main className="bg-white pt-8">
            <CategoryHeader headerDetails={headerDetails} />
            <ProductOverview productDetails={neusalDetails} />
            <ProductOverview productDetails={rinseSbDetails} />            
        </main>
    </Layout>
  )
}