
import React from "react"

export default function ProductOverview(props) {
    return (
        <section aria-labelledby="features-heading" className="relative mx-auto mt-10 max-w-7xl">
            <div className="aspect-w-3 aspect-h-2 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:w-1/2 lg:h-full">
            <img
                src={props.productDetails.productImage}
                alt="NEUSAL 100ml bottle"
                className="h-full w-full object-top object-scale-down lg:h-full lg:w-full"
            />
            </div>
            <div className="max-w-2xl mx-auto pb-24 px-4 sm:pb-32 sm:px-6 lg:max-w-7xl lg:px-4 lg:grid lg:grid-cols-2">
            <div className="lg:col-start-2">
                <h3 id="features-heading" className="text-gray-500 text-center lg:text-left">{props.productDetails.productDescription}</h3>
                <h1 className="mt-1 text-8xl sm:text-6xl lg:text-left text-center font-bold text-brand tracking-tight">{props.productDetails.productName}</h1>
                <h2 className="mt-4 font-bold text-brand-dark">Intended use</h2>
                <ul className="text-sm ml-4 my-2 font-medium text-gray-500 list-disc">
                    {props.productDetails.intendedUsages.map((item) => (
                    <li key={item.name}>{item.usage}</li>
                    ))}
                </ul>
                <h2 className="mt-4 font-bold text-brand-dark">Product contents</h2>
                <p className="text-sm text-gray-500">{props.productDetails.contentDescription}</p>
                <ul className="text-sm ml-4 my-2 font-medium text-gray-500 list-disc">
                    {props.productDetails.contents.map((item) => (
                        <li key={item.name}>
                        {item.content}                      
                        </li>
                    ))}
                </ul>
                <p className="text-sm text-gray-500">{props.productDetails.contentDisclaimer}</p>
                <h2 className="mt-4 font-bold text-brand-dark">Bottle sizes</h2>
                <p className="text-sm text-gray-500">{props.productDetails.productName} is a available in the following bottle sizes</p>
                <ul className="text-sm ml-4 my-2 font-medium text-gray-500 list-disc">
                    {props.productDetails.sizes.map((item) => (
                        <li key={item.name}>
                        {item.sizeDescription}                      
                        </li>
                    ))}
                </ul>
                <p className="text-sm mt-4 text-gray-500">{props.productDetails.deviceDisclaimer}</p>
            </div>
            </div>
        </section>
    )
}